<template>
  <v-menu
    bottom
    left
    min-width="200"
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>

    <v-list :tile="false" flat nav>
      <template v-for="(p, i) in profile">
        <div v-if="p.changePassword" :key="`item-${i}`">
          <v-dialog v-model="dialogPassword" width="500">
            <template v-slot:activator="{ on, attrs }">
              <app-bar-item>
                <v-list-item-title
                  v-text="$t('settings.settingsList.password.button')"
                  v-bind="attrs"
                  v-on="on"
                />
              </app-bar-item>
            </template>

            <v-form ref="formPassword" v-model="validPassword" lazy-validation>
              <v-card>
                <v-card-title>
                  <span
                    class="primary--text font-weight-bold text-h5"
                    v-text="$t('settings.settingsList.password.form.title')"
                  />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-text-field
                    v-model="dataFormPassword.passwordCurrent"
                    outlined
                    dense
                    :append-icon="
                      showPasswordCurrent ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    :rules="[
                      passwordRules.required,
                      passwordRules.min,
                      passwordRules.max,
                    ]"
                    :type="showPasswordCurrent ? 'text' : 'password'"
                    :label="
                      $t('settings.settingsList.password.form.currentPassword')
                    "
                    :placeholder="
                      $t(
                        'settings.settingsList.password.form.retypeCurrentPassword'
                      )
                    "
                    @click:append="showPasswordCurrent = !showPasswordCurrent"
                    required
                  />
                  <v-text-field
                    v-model="dataFormPassword.password"
                    outlined
                    dense
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[
                      passwordRules.required,
                      passwordRules.number,
                      passwordRules.min,
                      passwordRules.max,
                    ]"
                    :type="showPassword ? 'text' : 'password'"
                    :label="
                      $t('settings.settingsList.password.form.newPassword')
                    "
                    :placeholder="
                      $t(
                        'settings.settingsList.password.form.retypeNewPassword'
                      )
                    "
                    @click:append="showPassword = !showPassword"
                    required
                  />
                  <v-text-field
                    v-model="dataFormPassword.passwordConfirm"
                    outlined
                    dense
                    :append-icon="
                      showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    :rules="[passwordRules.required, confirmPassword]"
                    :type="showPasswordConfirm ? 'text' : 'password'"
                    :label="
                      $t(
                        'settings.settingsList.password.form.retypeNewPassword'
                      )
                    "
                    :placeholder="
                      $t(
                        'settings.settingsList.password.form.retypeNewPassword'
                      )
                    "
                    @click:append="showPasswordConfirm = !showPasswordConfirm"
                    required
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue-grey"
                    @click="closeDialogPassword"
                    v-text="$t('settings.settingsList.actions.cancel')"
                  />
                  <v-btn
                    color="info"
                    :disabled="!validPassword"
                    @click="save"
                    v-text="$t('settings.settingsList.actions.save')"
                  />
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </div>
        <v-divider v-if="p.divider" :key="`divider-${i}`" class="mb-2 mt-2" />
        <!-- click into the Logout -->
        <app-bar-item
          v-if="p.title"
          :key="`item-${i}`"
          @click.native="clickLogout(p)"
        >
          <v-list-item-title v-text="p.title" />
        </app-bar-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { sync } from "vuex-pathify";
export default {
  name: "DefaultAccount",

  data() {
    return {
      profile: [],
      logout: this.$t("layout.appbar.logout"),
      dialogPassword: false,
      validPassword: true,
      showPassword: false,
      showPasswordCurrent: false,
      showPasswordConfirm: false,
      passwordRules: {
        required: (v) => !!v || "Password is required",
        number: (v) =>
          /^(?=.*[0-9])(?=.*[!@#$%^&*])/.test(v) ||
          "Number and special characters",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
        max: (v) => (v && v.length <= 20) || "Max 20 characters",
      },
    };
  },
  created() {
    this.profile = [
      // get value email from function getEmailLogin() from index Mixins
      { title: this.getEmailLogin() },
      { title: "Settings" },
      { changePassword: true },
      { divider: true },
      { title: this.$t("layout.appbar.logout") },
    ];
  },
  computed: {
    confirmPassword() {
      return () =>
        this.dataFormPassword.password ===
          this.dataFormPassword.passwordConfirm ||
        "The password is not correct";
    },
    ...sync("adminSetting", ["dataFormPassword"]),
  },
  methods: {
    async closeDialogPassword() {
      this.$refs["formPassword"][0].reset();
      this.dialogPassword = false;
    },
    clickLogout(p) {
      // if title = "Log out" then clear localStorage and reload page
      if (p.title === this.logout) {
        localStorage.clear();
        location.reload();
      }
    },
    async save() {
      const valueCheck = this.$refs["formPassword"][0].validate();

      // if valueCheck = false then stop function save

      if (!valueCheck) {
        return;
      }
      this.dataFormPassword.id = this.getUserIdLogin();
      const dataAdminSettingResult = await this.$store.dispatch(
        "adminSetting/changePassUserLogin",
        this.getTokenLogin()
      );
      if (dataAdminSettingResult.status === false) {
        this.$toast.error("Mật khẩu hiện tại không chính xác");
        return;
      }
      this.$toast.success("Đổi mật khẩu thành công");
      this.$refs["formPassword"][0].reset();
      this.dialogPassword = false;
    },
  },
};
</script>
