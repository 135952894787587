var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"bottom":"","left":"","min-width":"200","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}])},[_c('v-list',{attrs:{"tile":false,"flat":"","nav":""}},[_vm._l((_vm.profile),function(p,i){return [(p.changePassword)?_c('div',{key:("item-" + i)},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('app-bar-item',[_c('v-list-item-title',_vm._g(_vm._b({domProps:{"textContent":_vm._s(_vm.$t('settings.settingsList.password.button'))}},'v-list-item-title',attrs,false),on))],1)]}}],null,true),model:{value:(_vm.dialogPassword),callback:function ($$v) {_vm.dialogPassword=$$v},expression:"dialogPassword"}},[_c('v-form',{ref:"formPassword",refInFor:true,attrs:{"lazy-validation":""},model:{value:(_vm.validPassword),callback:function ($$v) {_vm.validPassword=$$v},expression:"validPassword"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"primary--text font-weight-bold text-h5",domProps:{"textContent":_vm._s(_vm.$t('settings.settingsList.password.form.title'))}})]),_c('v-divider'),_c('v-card-text',[_c('v-text-field',{attrs:{"outlined":"","dense":"","append-icon":_vm.showPasswordCurrent ? 'mdi-eye' : 'mdi-eye-off',"rules":[
                    _vm.passwordRules.required,
                    _vm.passwordRules.min,
                    _vm.passwordRules.max ],"type":_vm.showPasswordCurrent ? 'text' : 'password',"label":_vm.$t('settings.settingsList.password.form.currentPassword'),"placeholder":_vm.$t(
                      'settings.settingsList.password.form.retypeCurrentPassword'
                    ),"required":""},on:{"click:append":function($event){_vm.showPasswordCurrent = !_vm.showPasswordCurrent}},model:{value:(_vm.dataFormPassword.passwordCurrent),callback:function ($$v) {_vm.$set(_vm.dataFormPassword, "passwordCurrent", $$v)},expression:"dataFormPassword.passwordCurrent"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"rules":[
                    _vm.passwordRules.required,
                    _vm.passwordRules.number,
                    _vm.passwordRules.min,
                    _vm.passwordRules.max ],"type":_vm.showPassword ? 'text' : 'password',"label":_vm.$t('settings.settingsList.password.form.newPassword'),"placeholder":_vm.$t(
                      'settings.settingsList.password.form.retypeNewPassword'
                    ),"required":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.dataFormPassword.password),callback:function ($$v) {_vm.$set(_vm.dataFormPassword, "password", $$v)},expression:"dataFormPassword.password"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","append-icon":_vm.showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off',"rules":[_vm.passwordRules.required, _vm.confirmPassword],"type":_vm.showPasswordConfirm ? 'text' : 'password',"label":_vm.$t(
                      'settings.settingsList.password.form.retypeNewPassword'
                    ),"placeholder":_vm.$t(
                      'settings.settingsList.password.form.retypeNewPassword'
                    ),"required":""},on:{"click:append":function($event){_vm.showPasswordConfirm = !_vm.showPasswordConfirm}},model:{value:(_vm.dataFormPassword.passwordConfirm),callback:function ($$v) {_vm.$set(_vm.dataFormPassword, "passwordConfirm", $$v)},expression:"dataFormPassword.passwordConfirm"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue-grey"},domProps:{"textContent":_vm._s(_vm.$t('settings.settingsList.actions.cancel'))},on:{"click":_vm.closeDialogPassword}}),_c('v-btn',{attrs:{"color":"info","disabled":!_vm.validPassword},domProps:{"textContent":_vm._s(_vm.$t('settings.settingsList.actions.save'))},on:{"click":_vm.save}})],1)],1)],1)],1)],1):_vm._e(),(p.divider)?_c('v-divider',{key:("divider-" + i),staticClass:"mb-2 mt-2"}):_vm._e(),(p.title)?_c('app-bar-item',{key:("item-" + i),nativeOn:{"click":function($event){return _vm.clickLogout(p)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(p.title)}})],1):_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }